.line {
	width: 200px;
        padding-left: 0px;
        height:40px;
}

.itemlist {
	/* width: 200px; */
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid rgab(236, 236, 236,0.5);
}

.itemlist:hover {
	background-color: #ffffff;
}

.invisible {
	visibility: hidden;
}

.invisible {
	visibility: hidden;
	transform: translate3d(0px, -100px, 0);
}

.slideup,
.slidedown {
	animation: move 10s steps(10) infinite alternate;
}
.slidedown {
	max-height: 60px;
}

@keyframes slide {
	from {
		top: 0;
		left: 0;
	}
	to {
		top: 100px;
		left: 100px;
	}
}

@keyframes fontbulger {
	0% {
		font-size: 10px;
	}
	30% {
		font-size: 15px;
	}
	100% {
		font-size: 12px;
	}
}

.fontbulger {
	animation: fontbulger 2s infinite;
}

.box {
	animation-name: bounce;
	animation-duration: 4s; /* or: Xms */
	animation-iteration-count: 10;
	animation-direction: alternate; /* or: normal */
	animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
	animation-fill-mode: forwards; /* or: backwards, both, none */
	animation-delay: 2s; /* or: Xms */
}

@keyframes slideup100 {
	from {
		transform: translate3d(0, -10px, 0);
	}
	to {
		transform: translate3d(0px, 1px, 0);
	}
}

.slideup100 {
	animation-name: slideup100;
	animation-duration: 0.5s; /* or: Xms */
	animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
	animation-fill-mode: forwards; /* or: backwards, both, none */
	animation-delay: 0s; /* or: Xms */
 	width: 200px;
}


@keyframes slidedown10 {
	from {
		transform: translate3d(0, 1px, 0);
	}
	to {
                transform: translate3d(0px, -10px, 0);
                visibility: hidden;
                opacity : 0;
	}
}

.slidedown10 {
	animation-name: slidedown10;
	animation-duration: 0.2s; /* or: Xms */
	animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
	animation-fill-mode: forwards; /* or: backwards, both, none */
	animation-delay: 0s; /* or: Xms */
	width: 200px;
}

.title-value {

	font-weight:800;
}

/* button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]: */