.iconwrap {
    width:38px;
    height:38px;
}

.icon {
    width:24px;
    height:24px;
}

.inline-icon {
    display:inline-block;
    width:24px;
    height:24px;
}