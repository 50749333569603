@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
.iconwrap {
    width:38px;
    height:38px;
}

.icon {
    width:24px;
    height:24px;
}

.inline-icon {
    display:inline-block;
    width:24px;
    height:24px;
}
@keyframes animate-bar {
    0% {
        left: -80%;
        right: 100%;
      }
      
      100% {
        left: 100%;
        right: -80%;
      }
}
.progress-bar-wrap {
    top:0;
    width:100%;
    height: 3px;
    overflow: hidden;
    position: relative;
    z-index: 1000;
}
.progress-bar-bar {
    animation: animate-bar 3s  cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
    position: relative;
    top:0px;
    height:3px;
    width:100%;
    background-color: #f8b611;
}
.tree-container {
    display: flex;
    justify-content: center;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .tree-node {
    text-align: center;
    margin-top: 20px;
    position: relative;
  }
  
  .node-label {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .node-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .tree-children {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    grid-gap: 20px;
    gap: 20px;
    position: relative;
  }
  
  .tree-children::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    width: 2px;
    height: 10px;
    background: #ddd;
  }
  
  .tree-children::after {
    content: "";
    position: absolute;
    top: -10px;
    width: 100%;
    height: 2px;
    background: #ddd;
  }
  
  .tree-node:not(:first-child)::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    width: 2px;
    height: 20px;
    background: #ddd;
  }
  
  .tree-node > .tree-children {
    margin-top: 30px; 
    padding-left: 20px; 
  }
  
  .tree-node > .tree-children > .tree-node {
    margin-left: 20px; 
  }
  
  .tree-node > .tree-children > .tree-node:first-child {
    margin-left: 0;
  }
  
* {
  padding: 0;
  margin: 0;
}
.header {
  height: 45px;
  line-height: 45px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: block;
  margin: 0;
  width: 100%;
  min-width: 1024px;
  height: 48px;
  max-height: 44px;
  background: rgba(221, 221, 221, 0.92);
  font-size: 17px;
  -webkit-user-select: none;
  user-select: none;
  padding-left: 60px;
}
.follow {
  float: right;
  margin-right: 79px;
}
.follow img {
  width: 25px;
  position: relative;
  top: 7px;
}

body {
  font-family: "Sen", sans-serif;
  background: #f9f9fa;
}

.container {
  width: 90%;
  margin: 7% auto;
}

.follow a {
  color: #131313;
  text-decoration: none;
}

.org-tree ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.org-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.org-tree li::before,
.org-tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}
.org-tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.org-tree li:only-child::after,
.org-tree li:only-child::before {
  display: none;
}

.org-tree li:only-child {
  padding-top: 0;
}

.org-tree li:first-child::before,
.org-tree li:last-child::after {
  border: 0 none;
}

.org-tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.org-tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.org-tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.org-tree li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.org-tree li a:hover,
.org-tree li a:hover + ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

.org-tree li a:hover + ul li::after,
.org-tree li a:hover + ul li::before,
.org-tree li a:hover + ul::before,
.org-tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

.card {
  position: relative;
  padding-top: 50px;
  /* width: 300px; */
}

.card .image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
  z-index: 100;
}
.card .image img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 13px solid #96f6b7;
}
.card .card-body {
  background: #fff;
  height: 56px;
  text-align: center;
  width: 300px;
  padding-top: 60px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}

.card .card-body h2 {
  font-weight: 600;
  line-height: 20px;
}
.card .card-body p {
  line-height: 20px;
  font-size: small;
}
.card .card-footer {
  padding: 3px;
  background: #96f6b7;
  text-align: center;
  bottom: 0;
  position: relative;
  width: 295px;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}
.card .card-footer img {
  width: 25px;
  margin: 5px;
}

.line {
	width: 200px;
        padding-left: 0px;
        height:40px;
}

.itemlist {
	/* width: 200px; */
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid rgab(236, 236, 236,0.5);
}

.itemlist:hover {
	background-color: #ffffff;
}

.invisible {
	visibility: hidden;
}

.invisible {
	visibility: hidden;
	transform: translate3d(0px, -100px, 0);
}

.slideup,
.slidedown {
	animation: move 10s steps(10) infinite alternate;
}
.slidedown {
	max-height: 60px;
}

@keyframes slide {
	from {
		top: 0;
		left: 0;
	}
	to {
		top: 100px;
		left: 100px;
	}
}

@keyframes fontbulger {
	0% {
		font-size: 10px;
	}
	30% {
		font-size: 15px;
	}
	100% {
		font-size: 12px;
	}
}

.fontbulger {
	animation: fontbulger 2s infinite;
}

.box {
	animation-name: bounce;
	animation-duration: 4s; /* or: Xms */
	animation-iteration-count: 10;
	animation-direction: alternate; /* or: normal */
	animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
	animation-fill-mode: forwards; /* or: backwards, both, none */
	animation-delay: 2s; /* or: Xms */
}

@keyframes slideup100 {
	from {
		transform: translate3d(0, -10px, 0);
	}
	to {
		transform: translate3d(0px, 1px, 0);
	}
}

.slideup100 {
	animation-name: slideup100;
	animation-duration: 0.5s; /* or: Xms */
	animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
	animation-fill-mode: forwards; /* or: backwards, both, none */
	animation-delay: 0s; /* or: Xms */
 	width: 200px;
}


@keyframes slidedown10 {
	from {
		transform: translate3d(0, 1px, 0);
	}
	to {
                transform: translate3d(0px, -10px, 0);
                visibility: hidden;
                opacity : 0;
	}
}

.slidedown10 {
	animation-name: slidedown10;
	animation-duration: 0.2s; /* or: Xms */
	animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
	animation-fill-mode: forwards; /* or: backwards, both, none */
	animation-delay: 0s; /* or: Xms */
	width: 200px;
}

.title-value {

	font-weight:800;
}

/* button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]: */
body {
    font-family: 'Montserrat', sans-serif;
  }
.custom-header {
    background-color: #CBE7FC !important;
    color: gray !important;
    font-weight: bold;
}
.custom-header {
    background-color: #CBE7FC !important;
    color: gray !important;
    font-weight: bold;
}
html,
body {
  font-family: 'Montserrat', sans-serif;
  background-color: white !important;
}

input,
input [type=submit],
button {
  padding: 0px;
  margin: 0px;
  outline: none !important;
  box-sizing: border-box;
  cursor: pointer;
}


element {

  padding-left: 24px;

}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  font-size: "1em";
  font-weight: 499;
  color: "rgb(39, 43, 65)";
}
.ant-menu-item-selected {
  color: "#5f63f2";
  background-color: #f0f3ff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: "#5f63f2";
  background-color: #f0f3ff;
}

.slide-pane__header{
  background-color:white;
  color: #045998;
}
.slide-pane__title{
  color:  #045998;
}
.slide-pane__title-wrapper{
  margin-left: 0;
}
.MuiStack-root.css-1lpsqxg-MuiStack-root{
  padding: 0;
}
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 10px 10px;
}
