@keyframes animate-bar {
    0% {
        left: -80%;
        right: 100%;
      }
      
      100% {
        left: 100%;
        right: -80%;
      }
}
.progress-bar-wrap {
    top:0;
    width:100%;
    height: 3px;
    overflow: hidden;
    position: relative;
    z-index: 1000;
}
.progress-bar-bar {
    animation: animate-bar 3s  cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
    position: relative;
    top:0px;
    height:3px;
    width:100%;
    background-color: #f8b611;
}