.tree-container {
    display: flex;
    justify-content: center;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .tree-node {
    text-align: center;
    margin-top: 20px;
    position: relative;
  }
  
  .node-label {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .node-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .tree-children {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
    position: relative;
  }
  
  .tree-children::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    width: 2px;
    height: 10px;
    background: #ddd;
  }
  
  .tree-children::after {
    content: "";
    position: absolute;
    top: -10px;
    width: 100%;
    height: 2px;
    background: #ddd;
  }
  
  .tree-node:not(:first-child)::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    width: 2px;
    height: 20px;
    background: #ddd;
  }
  
  .tree-node > .tree-children {
    margin-top: 30px; 
    padding-left: 20px; 
  }
  
  .tree-node > .tree-children > .tree-node {
    margin-left: 20px; 
  }
  
  .tree-node > .tree-children > .tree-node:first-child {
    margin-left: 0;
  }
  