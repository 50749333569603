@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html,
body {
  font-family: 'Montserrat', sans-serif;
  background-color: white !important;
}

input,
input [type=submit],
button {
  padding: 0px;
  margin: 0px;
  outline: none !important;
  box-sizing: border-box;
  cursor: pointer;
}


element {

  padding-left: 24px;

}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  font-size: "1em";
  font-weight: 499;
  color: "rgb(39, 43, 65)";
}
.ant-menu-item-selected {
  color: "#5f63f2";
  background-color: #f0f3ff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: "#5f63f2";
  background-color: #f0f3ff;
}

.slide-pane__header{
  background-color:white;
  color: #045998;
}
.slide-pane__title{
  color:  #045998;
}
.slide-pane__title-wrapper{
  margin-left: 0;
}
.MuiStack-root.css-1lpsqxg-MuiStack-root{
  padding: 0;
}
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 10px 10px;
}